import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ServiceAreas.css';

const ServiceAreas = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/about');
  };

  return (
    <section className="service-areas" aria-labelledby="service-areas-title">
      <h2 id="service-areas-title">Mobile Car Detailing - We Come to You!</h2>
      <p>Gloss Wise Detailing is proud to offer premium mobile detailing services across Coffs Harbour and surrounding areas, including:</p>
      <ul className="areas-list">
        <li>Coffs Harbour</li>
        <li>Urunga</li>
        <li>Sawtell</li>
        <li>Toormina</li>
        <li>Korora</li>
        <li>Valla</li>
        <li>Bellingen</li>
        <li>Bowraville</li>
        <li>Macksville</li>
        <li>Woolgoolga</li>
        <li>Boambee East</li>
        <li>Moonee Beach</li>
        <li>Corindi Beach</li>
        <li>Emerald Beach</li>
        <li>Nambucca Heads</li>
        <li>Sapphire Beach</li>
        <li>North Boambee Valley</li>
      </ul>
      <p>Wherever you are, Gloss Wise brings the quality and convenience of mobile car detailing right to your doorstep!</p>
      <button className="about-button" onClick={handleButtonClick}>
        Learn More About Us
      </button>
    </section>
  );
};

export default ServiceAreas;
