import React from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import ServiceAreas from '../components/ServiceAreas';
import Gallery from '../components/Gallery';

const galleryImages = [
  {
    thumbnail: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/471418806_122125909868575330_9044283466066588679_n.jpg?stp=c182.0.1077.1077a_dst-jpg_s552x414_tt6&_nc_cat=102&ccb=1-7&_nc_sid=50ad20&_nc_ohc=dTp5eqSjYtMQ7kNvgFIdLD9&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AI4rV_bTHFmlkatmIBgVa8o&oh=00_AYCO7-khRuMkGGZA_KZuElPcQhHgh66Chs7Ux9NKElnj2w&oe=678923E4",
    full: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/471418806_122125909868575330_9044283466066588679_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=833d8c&_nc_ohc=dTp5eqSjYtMQ7kNvgFIdLD9&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AOpwjP059u4Y29m5cirs88w&oh=00_AYD-EG7q80GFPGuGnM9J--mOj9azuNtplXK3yg3RE1hRng&oe=678923E4",
    alt: "Land Rover Sport - Ceramic Coating 1",
  },
  {
    thumbnail: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/471333481_122125909862575330_2400826393189938423_n.jpg?stp=c180.0.1080.1080a_dst-jpg_s552x414_tt6&_nc_cat=108&ccb=1-7&_nc_sid=50ad20&_nc_ohc=oG5ZXkehVhYQ7kNvgE64w6U&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AI4rV_bTHFmlkatmIBgVa8o&oh=00_AYAItOpNQlu3LskwAMdE7FugR8Ck-tCHIytfxIh2bpAH0Q&oe=6788FCD9",
    full: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/471333481_122125909862575330_2400826393189938423_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=833d8c&_nc_ohc=oG5ZXkehVhYQ7kNvgE64w6U&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AZud0i5z-yMaRvjlqR5oh_y&oh=00_AYD0I_FnkEoo2WM03-1qDQhnmgEmlnTkPT2SPWT5ZGmNsA&oe=67893519",
    alt: "Land Rover Sport - Ceramic Coating 2",
  },
  {
    thumbnail: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/469791668_122122433384575330_8163932805849653449_n.jpg?stp=c180.0.1080.1080a_dst-jpg_s552x414_tt6&_nc_cat=109&ccb=1-7&_nc_sid=50ad20&_nc_ohc=VSjLMOdjHmQQ7kNvgGTUKU1&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AJJlm2gtntIJoXfMsDPDXj0&oh=00_AYCF9r2rlZtz6-WUbmWcbOGixAm8FEMUPaTgCt8AiFmFXA&oe=678906D0",
    full: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/469791668_122122433384575330_8163932805849653449_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=833d8c&_nc_ohc=VSjLMOdjHmQQ7kNvgGTUKU1&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AfB3qeiTQWaewuxTDFKq57J&oh=00_AYCDCMgwnrESFAnaP19XQMs53g2VNWU_WVegeDWOjOQ4mw&oe=678906D0",
    alt: "Chevrolet ZR1 - Express Combo Detail 1",
  },
  {
    thumbnail: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/469797910_122122433390575330_6498986467508435278_n.jpg?stp=c182.0.1077.1077a_dst-jpg_s552x414_tt6&_nc_cat=110&ccb=1-7&_nc_sid=50ad20&_nc_ohc=XJbe4hy1jUUQ7kNvgGOqegd&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AJJlm2gtntIJoXfMsDPDXj0&oh=00_AYAFJJGF1Mywk_4lMPiNWhhjx76ZYBl-pN3eadJW0oLhHw&oe=678917C7",
    full: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/469797910_122122433390575330_6498986467508435278_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=833d8c&_nc_ohc=XJbe4hy1jUUQ7kNvgGOqegd&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AK0bgLHDJIwIB66WWseP65v&oh=00_AYDGIS7xKwk1Cgz2Jce1pCpi9Ux6DDLSHy3E5ckUw-PUEw&oe=678917C7",
    alt: "Chevrolet ZR1 - Express Combo Detail 2",
  },
  {
    thumbnail: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/468355367_122119308122575330_5431613662524769216_n.jpg?stp=c180.0.1080.1080a_dst-jpg_s552x414_tt6&_nc_cat=108&ccb=1-7&_nc_sid=50ad20&_nc_ohc=dN_dIVEjjdUQ7kNvgGKzjbh&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=A4NSriDipCXSR8RVf7vEo1H&oh=00_AYBISbG4D55avVGKSAoNSMmkhNa78Az1NaiIsZaazQHJAA&oe=67892512",
    full: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/468355367_122119308122575330_5431613662524769216_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=833d8c&_nc_ohc=dN_dIVEjjdUQ7kNvgGKzjbh&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AleO93VHD3bP7vFImmNLGtA&oh=00_AYDnlQCZlxvGXMrzzQXWSFZEmTsQOtzgFPj7hqifsxz57A&oe=67892512",
    alt: "Holden Comodore - Ceramic Coating 1",
  },
  {
    thumbnail: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/468328502_122119308182575330_7472130200937185995_n.jpg?stp=c182.0.1077.1077a_dst-jpg_s552x414_tt6&_nc_cat=100&ccb=1-7&_nc_sid=50ad20&_nc_ohc=9Q3Gv5BtfHYQ7kNvgHtzem5&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AQMWdBZvEr-r5Uha42anz7f&oh=00_AYCgBth6oylIgbnZ0ABPzN3qK8QdFhpcDtz6kHlVj9rddw&oe=6788FF8E",
    full: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/468328502_122119308182575330_7472130200937185995_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=833d8c&_nc_ohc=9Q3Gv5BtfHYQ7kNvgHtzem5&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AM_o-nbK3R-29JN83GmoOev&oh=00_AYD5xYa_Y6uLulb0iQtgXf02QOuJeSjEuEQMfzFAv2YH3A&oe=678937CE",
    alt: "Holden Comodore - Ceramic Coating 2",
  },
  {
    thumbnail: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/469480869_122122522064575330_5091320912944530632_n.jpg?stp=c182.0.1077.1077a_dst-jpg_s552x414_tt6&_nc_cat=102&ccb=1-7&_nc_sid=50ad20&_nc_ohc=GFbtYDG9-CEQ7kNvgGqG6ZR&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AtkIYHQZM2imtqSzX7JFUw7&oh=00_AYCioCBU5H6k0mUHjvohZ3p0rEOVB3tCDvK_v52bPZIYHw&oe=67891E53",
    full: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/469480869_122122522064575330_5091320912944530632_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=833d8c&_nc_ohc=GFbtYDG9-CEQ7kNvgGqG6ZR&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AEzuUkgL7lqQwxgW8gFNXxJ&oh=00_AYC6LotjUcPKomtWfXj8JSzrr4VXxFp4FwnxPbzLy2cfWg&oe=67891E53",
    alt: "Ford Ranger - Ceramic Coating 1",
  },
  
  {
    thumbnail: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/469644346_122122522436575330_5845317273036884239_n.jpg?stp=c180.0.1080.1080a_dst-jpg_s552x414_tt6&_nc_cat=111&ccb=1-7&_nc_sid=50ad20&_nc_ohc=W6XUi6V6NTAQ7kNvgFKtE8s&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=AtkIYHQZM2imtqSzX7JFUw7&oh=00_AYAYCX43KBrHD4w0Y6Lxpsys_VDNU7i0y2oZ8rlp4IsTgQ&oe=67892CA0",
    full: "https://scontent.fsyd13-1.fna.fbcdn.net/v/t39.30808-6/469644346_122122522436575330_5845317273036884239_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=833d8c&_nc_ohc=W6XUi6V6NTAQ7kNvgFKtE8s&_nc_zt=23&_nc_ht=scontent.fsyd13-1.fna&_nc_gid=APd7lYyQ8Y6NHzJxOdd8MDB&oh=00_AYBzBDNZFhnMFNVElixtM49kr2X6DUgmV5cduA2k6VXX1w&oe=67892CA0",
    alt: "Ford Ranger - Ceramic Coating 2",
  },
];

const fullGalleryLink = "https://www.facebook.com/profile.php?id=61567259901289&sk=photos";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Gloss Wise - Car Detailing & Ceramic Coating in Coffs Harbour, NSW</title>
        <link rel="canonical" href="https://glosswise.com.au/" />
        <meta
          name="description"
          content="Gloss Wise offers premium car detailing and ceramic coating services in Coffs Harbour, NSW. Enhance your car's appearance today!"
        />
      </Helmet>
      <Hero />
      <Services />
      <Testimonials />
      <Gallery
        images={galleryImages}
        fullGalleryLink={fullGalleryLink}
      />
      <ServiceAreas />
    </>
  );
};

export default Home;
