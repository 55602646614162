  import expressInteriorDetail from '../assets/images/expressInteriorDetail.webp';
  import fullInteriorDetail from '../assets/images/fullInteriorDetail.webp';
  import ceramicInteriorDetail from '../assets/images/ceramicInteriorDetail.webp';

  import expressExteriorDetail from '../assets/images/expressExteriorDetail.webp';
  import fullExteriorDetail from '../assets/images/fullExteriorDetail.webp';
  import ceramicExteriorDetail from '../assets/images/ceramicExteriorDetail.webp';

  import expressComboDetail from '../assets/images/expressComboDetail.webp';
  import fullComboDetail from '../assets/images/fullComboDetail.webp';
  import ceramicComboDetail from '../assets/images/ceramicComboDetail.webp';

  const servicesData = [
    {
      id: 1,
      title: 'Maintenance Interior Detail',
      category: 'Interior',
      description: 'A quick and affordable service designed to maintain your car’s interior cleanliness, perfect for vehicles that are regularly detailed.',
      price: 135,
      image: expressInteriorDetail,
      details: {
        benefits: [
          'Keeps your car looking tidy and professional.',
          'Affordable option for regular maintenance.',
          'Fast turnaround time.',
        ],
        included: [
          'Full vacuum of carpets, mats, and seats.',
          'Wiping down all interior surfaces (dashboard, doors, center console).',
          'Interior window cleaning for clear visibility.',
        ],
        disclaimer: 'Disclaimer: This service is intended for regularly maintained vehicles. Excessive pet hair, sand, or stains may require our Full or Premium options.',
      },
    },
    {
      id: 2,
      title: 'Full Interior Detail',
      category: 'Interior',
      description: 'A deep interior cleaning service that restores your car to like-new condition, tackling dirt, stains, and odors.',
      price: 300,
      image: fullInteriorDetail,
      details: {
        benefits: [
          'Restores your interior to like-new condition.',
          'Eliminates odors and tough stains.',
          'Perfect for families, pet owners, or anyone selling their car.',
        ],
        included: [
          'Thorough vacuuming of all carpets, mats, and upholstery.',
          'Steam cleaning for carpets and upholstery.',
          'Deep stain removal and fabric reconditioning.',
          'Complete cleaning of dashboard, door panels, and center console.',
          'Interior window cleaning for spotless clarity.',
        ],
      },
    },
    {
      id: 3,
      title: 'Ceramic Interior Detail',
      category: 'Interior',
      description: 'Our premier interior detailing package, combining deep cleaning and advanced protection for a flawless, long-lasting finish.',
      price: 600,
      image: ceramicInteriorDetail,
      details: {
        benefits: [
          'Provides long-term protection for interior surfaces, including fabrics, leather, and plastics.',
          "Enhances the interior's appearance with a luxurious, polished finish.",
          'Resists stains, spills, and odors, making ongoing maintenance easier.',
          'Ideal for luxury vehicles and clients seeking premium care.',
        ],
        included: [
          'Thorough vacuuming of all carpets, mats, and upholstery.',
          'Steam cleaning for carpets and upholstery.',
          'Deep stain removal and fabric reconditioning.',
          'Complete cleaning of dashboard, door panels, and center console.',
          'Interior window cleaning for spotless clarity.',
          'Deodorizing treatment to neutralize odors after detailing and ceramic application.',
          'Fabric protection for seats and carpets with hydrophobic and stain-resistant properties.',
          'Leather cleaning and conditioning with a specialized protective coating that ensures breathability, prevents mold, and provides durable, long-lasting protection.',
        ],
      },
    },
    {
      id: 4,
      title: 'Maintenance Exterior Detail',
      category: 'Exterior',
      description: 'An efficient exterior service to maintain your car’s cleanliness and protection against the elements.',
      price: 150,
      image: expressExteriorDetail,
      details: {
        benefits: [
          'Affordable option for regular exterior maintenance.',
          'Adds a temporary protective layer.',
          'Quick service to maintain your car’s appearance.',
        ],
        included: [
          'Pre-wash and gentle hand wash.',
          'Exterior windows cleaned to a streak-free finish.',
          'Application of SiO2 topper for up to 3-months hydrophobic protection.',
          'Tire cleaning and dressing for a polished look.',
        ],
        disclaimer: 'Disclaimer: This service is intended for regularly maintained vehicles. For heavily soiled cars or paint correction, consider our Full or Premium options.',
      },
    },
    {
      id: 5,
      title: 'Full Exterior Detail',
      category: 'Exterior',
      description: 'A comprehensive exterior service that restores shine and provides lasting protection for your vehicle’s paintwork.',
      price: 350,
      image: fullExteriorDetail,
      details: {
        benefits: [
          'Restores your vehicle’s showroom shine.',
          'Protects against environmental contaminants.',
          'Includes a durable 6-month protective coating.',
        ],
        included: [
          'Thorough pre-wash and hand wash.',
          'Clay bar treatment to remove embedded contaminants.',
          '1-step paint correction to enhance shine and remove light imperfections.',
          'Application of a 6-month hydrophobic protective coating.',
          'Tire cleaning and dressing for a polished look.',
        ],
      },
    },
    {
      id: 6,
      title: 'Ceramic Exterior Detail',
      category: 'Exterior',
      description: 'The ultimate exterior treatment with GTECHNIQ 5-year ceramic coating for unparalleled shine and protection.',
      price: 700,
      image: ceramicExteriorDetail,
      details: {
        benefits: [
          'Industry-leading ceramic coating for maximum durability.',
          'Prevents dust, dirt, and grime from sticking to your car.',
          'Long-lasting protection for up to 5 years.',
        ],
        included: [
          'Thorough pre-wash and hand wash.',
          'Clay bar treatment to remove embedded contaminants.',
          '1-step paint correction to enhance shine and remove light imperfections.',
          'Application of GTECHNIQ 2-layer ceramic coating for unmatched durability.',
          '2-year hydrophobic top layer for effortless cleaning.',
          'Enhanced UV protection to prevent paint fading.',
          'Tire cleaning and dressing for a polished look.',
        ],
      },
    },
    {
      id: 7,
      title: 'Maintenance Combo Detail',
      category: 'Combo',
      description: 'An efficient combination of interior and exterior maintenance detailing at a discounted rate, ideal for regularly maintained vehicles.',
      price: 250,
      savings: 35,
      image: expressComboDetail,
      details: {
        benefits: [
          'Affordable package for regular maintenance.',
          'Cleans both interior and exterior for a polished look.',
          'Fast and efficient service.',
        ],
        included: [
          'Full vacuum of carpets, mats, and seats.',
          'Wiping down all interior surfaces (dashboard, doors, center console).',
          'Interior window cleaning for clear visibility.',
          'Pre-wash and gentle hand wash.',
          'Exterior windows cleaned to a streak-free finish.',
          'Application of SiO2 topper for temporary hydrophobic protection.',
          'Tire cleaning and dressing for a polished look.',
        ],
        disclaimer: 'Disclaimer: This service is intended for regularly maintained vehicles. For excessive pet hair, sand, or paint correction, please consider our Full or Premium options.',
      },
    },
    {
      id: 8,
      title: 'Full Combo Detail',
      category: 'Combo',
      description: 'A comprehensive interior and exterior detailing service that restores your car to its original glory.',
      price: 600,
      savings: 50,
      image: fullComboDetail,
      details: {
          benefits: [
              'Perfect for those who want the whole package.',
              'Restores both interior and exterior to pristine condition.',
              'Includes stain removal and paint correction for a complete refresh.',
          ],
          included: [
              'Thorough vacuuming of all carpets, mats, and upholstery.',
              'Steam cleaning for carpets and upholstery.',
              'Deep stain removal and fabric reconditioning.',
              'Complete cleaning of dashboard, door panels, and center console.',
              'Interior window cleaning for spotless clarity.',
              
              'Thorough pre-wash and hand wash.',
              'Clay bar treatment to remove embedded contaminants.',
              '1-step paint correction to enhance shine and remove light imperfections.',
              'Application of a 6-month hydrophobic protective coating.',
              'Tire cleaning and dressing for a polished look.',
          ],
      },
  },
  {
      id: 9,
      title: 'Ceramic Combo Detail',
      category: 'Combo',
      description: 'The ultimate detailing package combining all ceramic interior and exterior services.',
      price: 1200,
      savings: 100,
      image: ceramicComboDetail,
      details: {
          benefits: [
              'Ideal for luxury vehicles or special occasions.',
              'Provides maximum protection and lasting beauty.',
              'Combines top-tier interior and exterior treatments.',
          ],
          included: [
              'Thorough vacuuming of all carpets, mats, and upholstery.',
              'Steam cleaning for carpets and upholstery.',
              'Deep stain removal and fabric reconditioning.',
              'Complete cleaning of dashboard, door panels, and center console.',
              'Interior window cleaning for spotless clarity.',
              'Deodorizing treatment to neutralize odors after detailing and ceramic application.',
              'Fabric protection for seats and carpets with hydrophobic and stain-resistant properties.',
              'Leather cleaning and conditioning with a specialized protective coating that ensures breathability, prevents mold, and provides durable, long-lasting protection.',
              
              'Thorough pre-wash and hand wash.',
              'Clay bar treatment to remove embedded contaminants.',
              '1-step paint correction to enhance shine and remove light imperfections.',
              'Application of GTECHNIQ 2-layer ceramic coating for unmatched durability.',
              '2-year hydrophobic top layer for effortless cleaning.',
              'Enhanced UV protection to prevent paint fading.',
              'Tire cleaning and dressing for a polished look.',
          ],
      },
  },
  ];
  
  export default servicesData;
