import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './About.css';

const About = () => {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>About Gloss Wise - Mobile Car Detailing Experts in Coffs Harbour</title>
        <link rel="canonical" href="https://glosswise.com.au/about" />
        <meta
          name="description"
          content="Learn about Gloss Wise Detailing, offering expert mobile car detailing and ceramic coating services in Coffs Harbour, NSW. Protect your car today!"
        />
      </Helmet>
      <div className="about-page" role="main" aria-labelledby="about-page-title">
        {/* Hero Section */}
        <section className="about-hero" aria-labelledby="about-hero-title">
          <div className="about-hero-content">
            <h1 id="about-hero-title">Expert Mobile Car Detailing in Coffs Harbour</h1>
            <p>Bringing professional car detailing and protection directly to your doorstep.</p>
          </div>
        </section>

        {/* Mission and Values */}
        <section className="about-mission" aria-labelledby="mission-title">
          <h2 id="mission-title">Our Mission</h2>
          <p>
            At Gloss Wise, we aim to redefine car care by delivering top-tier mobile detailing services. Our team
            combines expert craftsmanship with eco-friendly practices to protect and enhance your vehicle while
            prioritizing convenience and sustainability.
          </p>
          <h2 id="values-title" className="values-heading">Our Values</h2>
          <ul className="values-list" aria-labelledby="values-title">
            <li>Quality First</li>
            <li>Honesty & Transparency</li>
            <li>Commitment to Excellence</li>
            <li>Customer Satisfaction</li>
          </ul>
        </section>

        {/* Our Story */}
        <section className="about-story" aria-labelledby="story-title">
          <h2 id="story-title">Our Story</h2>
          <p>
            Gloss Wise was born from a passion for vehicles and a desire to raise the bar in car care. Based in Coffs
            Harbour, we’ve served countless clients with meticulous attention to detail, ensuring every car we touch
            looks its absolute best. From a simple clean to advanced ceramic coatings, we provide tailored solutions to
            meet our clients' needs.
          </p>
        </section>

        {/* Why Choose Us */}
        <section className="why-choose-us" aria-labelledby="why-choose-title">
          <h2 id="why-choose-title">Why Choose Gloss Wise?</h2>
          <div className="choose-us-cards">
            <div className="choose-card" role="region" aria-labelledby="expertise-title">
              <h3 id="expertise-title">Expertise You Can Trust</h3>
              <p>Years of experience in mobile detailing and paint protection.</p>
            </div>
            <div className="choose-card" role="region" aria-labelledby="satisfaction-title">
              <h3 id="satisfaction-title">100% Satisfaction Guarantee</h3>
              <p>We ensure you're thrilled with the results, or we’ll make it right.</p>
            </div>
            <div className="choose-card" role="region" aria-labelledby="eco-friendly-title">
              <h3 id="eco-friendly-title">Eco-Friendly Products</h3>
              <p>Top-tier, environmentally safe products to care for your car and the planet.</p>
            </div>
          </div>
        </section>

        {/* Why Ceramic Coatings Matter */}
        <section className="about-coatings" aria-labelledby="coatings-title">
          <h2 id="coatings-title">Why Ceramic Coatings Are Essential</h2>
          <p>
            In the Mid North Coast region, the high UV index poses a significant threat to your vehicle's paintwork.
            Without proper protection, cars frequently suffer from faded paint and clear coat peeling. Ceramic coatings
            act as a shield, providing UV protection, preventing oxidation, and enhancing your car’s shine.
          </p>
          <p>
            But that’s not all—ceramic coatings also provide a critical barrier against environmental hazards like tree
            sap, bird droppings, bat droppings, and industrial fallout, all of which can etch into and damage your
            paint. With a durable ceramic coating, these contaminants are far less likely to cause permanent harm,
            giving you peace of mind and a car that looks pristine.
          </p>
          <p>
            Our **GTECHNIQ 2-layer ceramic coating** offers unparalleled durability, with a 5-year guarantee and a
            hydrophobic top layer for effortless cleaning. It repels dirt, water, and contaminants, ensuring your car
            stays cleaner for longer while maintaining its showroom finish.
          </p>
        </section>

        {/* High-Quality Equipment and Chemicals */}
        <section className="quality-equipment" aria-labelledby="quality-title">
          <h2 id="quality-title">High-Quality Equipment and Chemicals</h2>
          <p>
            We rely on premium-grade tools and products to achieve exceptional results. Our services include advanced
            ceramic coatings, thorough detailing, and eco-friendly treatments. From cleaning to long-term protection,
            your car is in the best hands.
          </p>
          <p>
            Our ceramic coating services are specifically designed for the harsh Australian climate, offering superior
            UV protection and a self-cleaning effect that minimizes maintenance.
          </p>

          {/* Embedded Video */}
          <div className="video-container">
            <iframe
              width="1280"
              height="720"
              src="https://www.youtube.com/embed/H55WBUagx_c"
              title="Gtechniq Crystal Serum Light Showcase"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </section>

        {/* Call to Action */}
        <section className="about-cta" aria-labelledby="cta-title">
          <h2 id="cta-title">Ready to Protect Your Investment?</h2>
          <button
            className="cta-button"
            onClick={() => (window.location.href = 'https://gloss-wise-detailing.square.site/s/appointments')}
            aria-label="Book your car detailing service now"
          >
            Book Your Detailing Now
          </button>
        </section>
      </div>
    </>
  );
};

export default About;
