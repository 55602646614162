import React, { useState } from "react";
import "./Gallery.css";

const Gallery = ({ images, fullGalleryLink }) => {
  const [activeImage, setActiveImage] = useState(null);

  const openModal = (image) => {
    setActiveImage(image);
  };

  const closeModal = () => {
    setActiveImage(null);
  };

  const nextImage = () => {
    const currentIndex = images.findIndex((img) => img.full === activeImage.full);
    const nextIndex = (currentIndex + 1) % images.length;
    setActiveImage(images[nextIndex]);
  };

  const prevImage = () => {
    const currentIndex = images.findIndex((img) => img.full === activeImage.full);
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setActiveImage(images[prevIndex]);
  };

  return (
    <div className="gallery">
      <h2 className="gallery-title">Our Work</h2>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div
            className="gallery-item"
            key={index}
            onClick={() => openModal(image)}
          >
            <img
              src={image.thumbnail}
              alt={image.alt || "Gallery Image"}
              className="gallery-thumbnail"
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {activeImage && (
        <div className="modal-overlay" onClick={closeModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="modal-close"
              onClick={closeModal}
              aria-label="Close gallery"
            >
              &times;
            </button>
            <img
              src={activeImage.full}
              alt={activeImage.alt || "Gallery Image"}
              className="modal-image"
            />
            <div className="modal-navigation">
              <button
                className="prev-button"
                onClick={prevImage}
                aria-label="Previous image"
              >
                &#10094;
              </button>
              <button
                className="next-button"
                onClick={nextImage}
                aria-label="Next image"
              >
                &#10095;
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="full-gallery-link">
        <a
          href={fullGalleryLink}
          target="_blank"
          rel="noopener noreferrer"
          className="cta-button"
          aria-label="View the full gallery on Facebook"
        >
          View Full Gallery
        </a>
      </div>
    </div>
  );
};

export default Gallery;
